import Dashboard from "../Components/Page/Dashboard";
import Login from "../Components/Page/Login";

const Routes = [
  {
    path: "/login",
    exact: false,
    element: Login,
    isAuthenticated: false,
  },
  {
    path: "/",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/dashboard",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/branch",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/company",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/bookingcode",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/linkbookingcode",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/linksupplier",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policyperiod",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policytype",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/product",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/masterCompany",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/user",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/renewalPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/companyLogin",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/commisionReceivale",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/receivaleStatus",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/userPayable",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/branchPayable",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/chequeStatus",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/logStatus",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/officeExpenses",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/masterReport",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/paymentPaidAndReceived",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policyChequeReport",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policycreate",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/searchPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/receivableStatusNew",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policyList",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/ccentry",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/policyMapping",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/motorCalculation",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/userConfiguration",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/allPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/approvedPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/pendingPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/entryPending",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/rejectedPolicy",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/chequePending",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/client",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/subProduct",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/subBookingCode",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/fuelType",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/payout",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/userWallet",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/branchWallet",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/companyWallet",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/receivableConfiguration",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/branchConfiguration",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/rtoMapping",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/tdsReport",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/bookingReport",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/vehicleMake",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
  {
    path: "/MotorReport",
    exact: false,
    element: Dashboard,
    isAuthenticated: true,
  },
];
export default Routes;
