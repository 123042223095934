import React, { useState, useRef } from "react";
import { Box, Grid, Typography, Button, Autocomplete, TextField, Divider } from "@mui/material"
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { DownloadIcon } from "../../Resources/Icons/icons";
import { ToastError } from "../../UiComponents/Toaster/Toast";
import { GetVehicleDetails, GetTP, PostVehicleDetails } from "../../Service/_index";
import FloatLabel from "../../UiComponents/FloatLabel/FloatLabel";
import { Formik, Form } from "formik";
import moment from "moment/moment";
import './MotorCalculation.scss'
import { bike, privateCar, taxi, bus, gcvG3, threeWheeler, miscellaneousVehicle } from "./configJson"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { calcCarAndBike, calcBus, calcThreeWheeler, calcmisc } from "./VehiclePremiumCalculator";

const initialValues = {
    policyType: "",
    IDV: "",
    yearOfManufacture: "",
    zone: "",
    cc: "",
    discOnOD: "",
    accessoriesValue: "",
    noclaimBonus: "",
    zerodeprecation: "",
    PAownerDriver: "",
    PAunnamedPass: "",
    TPPDRestrict: "",
    seatingCapacity: "",
    LLtoPaidDriver: "",
    premiumYear: "",
    imt23: true,
    cooliesOrCleaner: "",
    GVW: "",
    quoteId: "",
};


const Calculations = (props) => {

    const { setOpen, title, resultValue, selectedVehicle } = props;
    let formRef = useRef(null);

    const [tpData, setTpData] = useState(null)
    const [isFirst, setIsfirst] = useState(true)
    const [inputData, setInputData] = useState({ formId: selectedVehicle.id })


    const BoxStyle = {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 1.4,
        backgroundColor: '#F3F3F3',
        padding: '14px',
        borderRadius: '20px',
        padding: '24px 20px 24px 20px'

    }
    const [viewData, setViewData] = useState({})

    const handleDownload = () => {
        if (inputData.policyType && ((inputData.policyType == "Full" && inputData.zone) || inputData.policyType != "Full")) {


            var doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.width;

            const leftMargin = 14;
            const rightMargin = 14;
            let yPosition = 20;

            doc.setFont("Inter", "bold");
            doc.setFontSize(12);

            doc.text(inputData?.company || "", leftMargin, 10);
            doc.setFontSize(10);
            doc.text(inputData?.customerName || "", leftMargin, 16);
            yPosition = 20;
            doc.setDrawColor(192, 192, 192);
            doc.setFontSize(10);
            doc.text(title, pageWidth - rightMargin - doc.getTextWidth(title), 10);
            doc.text(`Vehicle Number : ${inputData?.vehicleNumber || ""}`, pageWidth - rightMargin - doc.getTextWidth(`Vehicle Number : ${inputData?.vehicleNumber || ""}`), 16);
            doc.text(`Make Model : ${inputData?.makeModel || ""}`, pageWidth - rightMargin - doc.getTextWidth(`Make Model : ${inputData?.makeModel || ""}`), 22);

            yPosition += 10;
            const tableStartY = yPosition;
            const tableWidth = pageWidth - leftMargin - rightMargin;
            const rowHeight = 10;
            doc.rect(leftMargin, tableStartY, tableWidth * 0.5, rowHeight);
            doc.text("IDV", leftMargin + (tableWidth * 0.5 - doc.getTextWidth("IDV")) / 2, tableStartY + 6);
            doc.rect(leftMargin + tableWidth * 0.5, tableStartY, tableWidth * 0.5, rowHeight);
            doc.text(viewData?.iDV || 0, leftMargin + tableWidth * 0.5 + (tableWidth * 0.5 - doc.getTextWidth(viewData?.iDV.toString())) / 2, tableStartY + 6);

            doc.setFont("helvetica", "normal");
            yPosition = tableStartY + rowHeight
            const box1Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box1Width, rowHeight);
            doc.text("Year of Manufacture", leftMargin + 2, yPosition + 6);
            doc.rect(leftMargin + box1Width, yPosition, box1Width, rowHeight);
            doc.text((viewData?.yearOfManufacture || "-").toString(), leftMargin + box1Width + 2, yPosition + 6);
            const box2X = leftMargin + box1Width * 2;
            doc.rect(box2X, yPosition, box1Width, rowHeight);
            doc.text("Zone", box2X + 2, yPosition + 6);
            const box3X = leftMargin + box1Width * 3;
            doc.rect(box3X, yPosition, box1Width, rowHeight);
            doc.text((viewData?.zone || "-").toString(), box3X + 2, yPosition + 6);


            yPosition = yPosition + rowHeight
            const box2Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box2Width, rowHeight);
            doc.text("LPG Kit-A", leftMargin + 2, yPosition + 6);
            doc.rect(leftMargin + box2Width, yPosition, box2Width, rowHeight);
            doc.text((viewData?.lPGKit_A?.toFixed(2) || 0).toString(), leftMargin + box2Width + 2, yPosition + 6);
            const box22X = leftMargin + box2Width * 2;
            doc.rect(box22X, yPosition, box2Width, rowHeight);
            doc.text("Liability Premium", box22X + 2, yPosition + 6);
            const box23X = leftMargin + box2Width * 3;
            doc.rect(box23X, yPosition, box2Width, rowHeight);
            doc.text((viewData?.LP || "-").toString(), box23X + 2, yPosition + 6);

            yPosition = yPosition + rowHeight
            const box3Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box3Width, rowHeight);
            doc.text("OD Premium", leftMargin + 2, yPosition + 6);
            doc.rect(leftMargin + box3Width, yPosition, box3Width, rowHeight);
            doc.text((viewData?.OdPremium || 0).toString(), leftMargin + box3Width + 2, yPosition + 6);
            const box32X = leftMargin + box3Width * 2;
            doc.rect(box32X, yPosition, box3Width, rowHeight);
            doc.text("LPG KIT - B", box32X + 2, yPosition + 6);
            const box33X = leftMargin + box3Width * 3;
            doc.rect(box33X, yPosition, box3Width, rowHeight);
            doc.text((viewData?.lPGKit_B?.toFixed(2) || 0).toString(), box33X + 2, yPosition + 6);

            yPosition = yPosition + rowHeight
            const box4Width = tableWidth * 0.25;
            const wrappedText = doc.splitTextToSize("Discount on OD Premium", box4Width - 4);
            doc.rect(leftMargin, yPosition, box4Width, rowHeight);
            doc.text(wrappedText, leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
            doc.rect(leftMargin + box4Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.discountonODPremium || 0).toString(), leftMargin + box4Width + 2, yPosition + 6);
            doc.rect(leftMargin + box4Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.discountonODPremiumValue || 0).toString(), leftMargin + box4Width + 2 + tableWidth * 0.25 / 2, yPosition + 6);
            const box42X = leftMargin + box4Width * 2;
            doc.rect(box42X, yPosition, box4Width, rowHeight);
            doc.text("PA to Owner Driver", box42X + 2, yPosition + 6);
            const box43X = leftMargin + box4Width * 3;
            doc.rect(box43X, yPosition, box4Width, rowHeight);
            doc.text((viewData?.pAOwnerDriver || 0).toString(), box43X + 2, yPosition + 6);


            yPosition = yPosition + rowHeight
            const box5Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box5Width, rowHeight + 1);
            doc.text(doc.splitTextToSize("Basic Od Premium After Discount", box4Width - 4), leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
            doc.rect(leftMargin + box5Width, yPosition, box5Width, rowHeight + 1);
            doc.text((viewData?.basicODPremiumAfterDiscount || 0).toString(), leftMargin + box5Width + 2, yPosition + 6);
            const box52X = leftMargin + box5Width * 2;
            doc.rect(box52X, yPosition, box5Width, rowHeight + 1);
            doc.text("LL to Paid Driver", box52X + 2, yPosition + 6);
            const box53X = leftMargin + box5Width * 3;
            doc.rect(box53X, yPosition, box5Width, rowHeight + 1);
            doc.text((viewData?.lLtoPaidDriver || 0).toString(), box53X + 2, yPosition + 6);


            yPosition = yPosition + rowHeight + 1
            const box6Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box6Width, rowHeight);
            doc.text("Accessories Value", leftMargin + 2, yPosition + 6);
            doc.rect(leftMargin + box6Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.accessories || 0).toString(), leftMargin + box6Width + 2, yPosition + 6);

            doc.rect(leftMargin + box6Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.accessories || 0).toString(), leftMargin + box6Width + 2 + tableWidth * 0.25 / 2, yPosition + 6);

            const box62X = leftMargin + box6Width * 2;
            if (inputData.formId === 1 || inputData.formId === 7 || inputData.formId === 8 || inputData.formId === 9 || inputData.formId === 10 || inputData.formId === !6) {
                doc.rect(box62X, yPosition, box6Width, rowHeight);
                doc.text("CC", box62X + 2, yPosition + 6);
                const box63X = leftMargin + box6Width * 3;
                doc.rect(box63X, yPosition, box6Width, rowHeight);
                doc.text((viewData?.cc || 0).toString(), box63X + 2, yPosition + 6);
            } else {
                doc.rect(box62X, yPosition, tableWidth * 0.50, rowHeight);

            }

            yPosition = yPosition + rowHeight
            const box7Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box7Width, rowHeight);
            doc.text("Total Basic Premium", leftMargin + 2, yPosition + 6);
            doc.rect(leftMargin + box7Width, yPosition, box7Width, rowHeight);
            doc.text((viewData?.totalBasicPremium || 0).toString(), leftMargin + box7Width + 2, yPosition + 6);
            const box72X = leftMargin + box7Width * 2;

            if (inputData.formId === 6 || inputData.formId === 7 || inputData.formId === 8 || inputData.formId === 9 || inputData.formId === 10) {
                doc.rect(box72X, yPosition, box7Width, rowHeight);
                doc.text("LL to Passengers", box72X + 2, yPosition + 6);
                const box73X = leftMargin + box7Width * 3;
                doc.rect(box73X, yPosition, box7Width, rowHeight);
                doc.text((viewData?.llToPassengers || 0).toString(), box73X + 2, yPosition + 6);
            } else {
                doc.rect(box72X, yPosition, tableWidth * 0.50, rowHeight);

            }


            yPosition = yPosition + rowHeight
            const box8Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box8Width, rowHeight);
            doc.text("No Claim Bonus", leftMargin + 2, yPosition + 6);

            doc.rect(leftMargin + box8Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.noClaimBonus || 0).toString(), leftMargin + box8Width + 2, yPosition + 6);

            doc.rect(leftMargin + box8Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.noClaimBonusValue || 0).toString(), leftMargin + box8Width + 2 + tableWidth * 0.25 / 2, yPosition + 6);

            const box82X = leftMargin + box8Width * 2;

            if (inputData.formId) {
                doc.rect(box82X, yPosition, box8Width, rowHeight);
                doc.text("PA to Unnamed  Passenger", box82X + 2, yPosition + 6);
                const box83X = leftMargin + box8Width * 3;
                doc.rect(box83X, yPosition, box8Width, rowHeight);
                doc.text((viewData?.pAUnnamedPassenger || 0).toString(), box83X + 2, yPosition + 6);
            } else {
                doc.rect(box82X, yPosition, tableWidth * 0.50, rowHeight);

            }


            yPosition = yPosition + rowHeight
            const box9Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box9Width, rowHeight + 1);
            doc.text(doc.splitTextToSize("Net Own Damage Premium", box9Width - 4), leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
            doc.rect(leftMargin + box9Width, yPosition, box9Width, rowHeight + 1);
            doc.text((viewData?.netOwnDamage || 0).toString(), leftMargin + box9Width + 2, yPosition + 6);
            const box92X = leftMargin + box9Width * 2;
            if (inputData.formId === !6 || inputData.formId === 8 || inputData.formId === 9) {
                doc.rect(box92X, yPosition, box9Width, rowHeight + 1);
                doc.text("TPPD Restrict", box92X + 2, yPosition + 6);
                const box93X = leftMargin + box9Width * 3;
                doc.rect(box93X, yPosition, box9Width, rowHeight + 1);
                doc.text((viewData?.tppd || 0).toString(), box93X + 2, yPosition + 6);
            } else {
                doc.rect(box92X, yPosition, tableWidth * 0.50, rowHeight + 1);

            }


            yPosition = yPosition + rowHeight + 1
            const box10Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box10Width, rowHeight);
            doc.text(doc.splitTextToSize("Zero Dep Premium", box10Width - 4), leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
            doc.rect(leftMargin + box10Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.zeroDepPremium || 0).toString(), leftMargin + box10Width + 2, yPosition + 6);

            doc.rect(leftMargin + box10Width, yPosition, tableWidth * 0.25 / 2, rowHeight);
            doc.text((viewData?.zeroDepPremiumValue || 0).toString(), leftMargin + box10Width + 2 + tableWidth * 0.25 / 2, yPosition + 6);

            const box102X = leftMargin + box10Width * 2;
            if (inputData.formId === !6 || inputData.formId === 8 || inputData.formId === 9) {
                doc.rect(box102X, yPosition, box10Width, rowHeight);
                doc.text("Coolies / Cleaner", box102X + 2, yPosition + 6);
                const box103X = leftMargin + box10Width * 3;
                doc.rect(box103X, yPosition, box10Width, rowHeight);
                doc.text((viewData?.CooliesAndCleaner || 0).toString(), box103X + 2, yPosition + 6);
            } else {
                doc.rect(box102X, yPosition, tableWidth * 0.50, rowHeight);

            }


            if (inputData.formId === 6 || inputData.formId === 7 || inputData.formId === 8 || inputData.formId === 9) {
                yPosition = yPosition + rowHeight
                const box11Width = tableWidth * 0.25;
                doc.rect(leftMargin, yPosition, box11Width, rowHeight);
                doc.text(doc.splitTextToSize("vehicle Basic Rate", box11Width - 4), leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
                doc.rect(leftMargin + box11Width, yPosition, box11Width, rowHeight);
                doc.text(`${(viewData?.vehiclebaseRate).toString()} - ${(viewData?.vehiclebaseRateValue).toString()}`, leftMargin + box11Width + 2, yPosition + 6);
                const box112X = leftMargin + box11Width * 2;
                doc.rect(box112X, yPosition, tableWidth * 0.50, rowHeight);
            }

            if (inputData.formId === 6 || inputData.formId === 7 || inputData.formId === 10) {
                yPosition = yPosition + rowHeight
                const box12Width = tableWidth * 0.25;
                doc.rect(leftMargin, yPosition, box12Width, rowHeight);
                doc.text("Seating Capacity", leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
                doc.rect(leftMargin + box12Width, yPosition, box12Width, rowHeight);
                doc.text(`${(viewData?.seatingCapacityAmount || 0).toString()} - ${(viewData?.seatingCapacityvalue || 0).toString()}`, leftMargin + box12Width + 2, yPosition + 6);
                const box122X = leftMargin + box12Width * 2;
                doc.rect(box122X, yPosition, tableWidth * 0.50, rowHeight);

            }

            if (inputData.formId === 6 || inputData.formId === 7 || inputData.formId === 8 || inputData.formId === 9 || inputData.formId === 10) {
                yPosition = yPosition + rowHeight
                const box13Width = tableWidth * 0.25;
                doc.rect(leftMargin, yPosition, box13Width, rowHeight);
                doc.text("Additional GVW", leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
                doc.rect(leftMargin + box13Width, yPosition, box13Width, rowHeight);
                doc.text((viewData?.additionalGVW || 0).toString(), leftMargin + box13Width + 2, yPosition + 6);
                const box132X = leftMargin + box13Width * 2;
                doc.rect(box132X, yPosition, tableWidth * 0.50, rowHeight);
            }


            if (inputData.formId === 6 || inputData.formId === 7 || inputData.formId === 8 || inputData.formId === 9 || inputData.formId === 10) {
                yPosition = yPosition + rowHeight
                const box14Width = tableWidth * 0.25;
                doc.rect(leftMargin, yPosition, box14Width, rowHeight);
                doc.text("IMT 23", leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
                doc.rect(leftMargin + box14Width, yPosition, box14Width, rowHeight);
                doc.text(`${(viewData?.imt23 || 0).toString()} - ${(viewData?.imt23Value || 0).toString()}`, leftMargin + box14Width + 2, yPosition + 6);
                const box142X = leftMargin + box14Width * 2;
                doc.rect(box142X, yPosition, tableWidth * 0.50, rowHeight);

            }


            yPosition = yPosition + rowHeight
            const box15Width = tableWidth * 0.25;
            doc.rect(leftMargin, yPosition, box15Width, rowHeight);
            doc.text("Total A [Od Premium]", leftMargin + 2, yPosition + 2, { align: "left", baseline: "top" });
            doc.rect(leftMargin + box15Width, yPosition, box15Width, rowHeight);
            doc.text((viewData?.totalAodPremium?.toFixed(2) || 0).toString(), leftMargin + box15Width + 2, yPosition + 6);
            const box152X = leftMargin + box15Width * 2;
            doc.rect(box152X, yPosition, box15Width, rowHeight);
            doc.text("Total B [Od Premium]", box152X + 2, yPosition + 6);
            const box153X = leftMargin + box15Width * 3;
            doc.rect(box153X, yPosition, box15Width, rowHeight);
            doc.text((viewData?.totalBodPremium || 0).toString(), box153X + 2, yPosition + 6);

            doc.setFont("Inter", "bold");

            yPosition = yPosition + rowHeight
            doc.rect(leftMargin, yPosition, tableWidth * 0.5, rowHeight);
            doc.text("Net Premium [A + B]", leftMargin + (tableWidth * 0.5 - doc.getTextWidth("Net Premium [A + B]")) / 2, yPosition + 6);
            doc.rect(leftMargin + tableWidth * 0.5, yPosition, tableWidth * 0.5, rowHeight);
            doc.text((viewData?.netPremiumAB || 0).toString(), leftMargin + tableWidth * 0.5 + (tableWidth * 0.5 - doc.getTextWidth(viewData?.netPremiumAB?.toString())) / 2, yPosition + 6);

            yPosition = yPosition + rowHeight
            doc.rect(leftMargin, yPosition, tableWidth * 0.5, rowHeight);
            doc.text("Gst@18%", leftMargin + (tableWidth * 0.5 - doc.getTextWidth("Gst@18%")) / 2, yPosition + 6);
            doc.rect(leftMargin + tableWidth * 0.5, yPosition, tableWidth * 0.5, rowHeight);
            doc.text((viewData?.tax || 0).toString(), leftMargin + tableWidth * 0.5 + (tableWidth * 0.5 - doc.getTextWidth(viewData?.tax?.toString())) / 2, yPosition + 6);

            doc.setTextColor(0, 0, 255);
            yPosition = yPosition + rowHeight
            doc.rect(leftMargin, yPosition, tableWidth * 0.5, rowHeight);
            doc.text("Final Premium", leftMargin + (tableWidth * 0.5 - doc.getTextWidth("Final Premium")) / 2, yPosition + 6);
            doc.rect(leftMargin + tableWidth * 0.5, yPosition, tableWidth * 0.5, rowHeight);
            doc.text((viewData?.finalPremium || 0).toString(), leftMargin + tableWidth * 0.5 + (tableWidth * 0.5 - doc.getTextWidth(viewData?.finalPremium?.toString())) / 2, yPosition + 6);

            yPosition = yPosition + rowHeight
            doc.setFont("Inter", "bold");
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(14);
            doc.text("Documents Required :-", leftMargin, yPosition + 14);

            yPosition = yPosition + rowHeight
            doc.setFont("helvetica", "normal");
            doc.setTextColor(0, 0, 255);
            doc.setFontSize(10);
            doc.text("1.Previous Policy Copy   2.RC Copy", leftMargin, yPosition + 14);

            yPosition = yPosition + rowHeight
            doc.setFont("helvetica", "bold");
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(12);
            doc.text("Note : In case of any claim , NCB will be revised and hence Quotation is Subject to Change", leftMargin, yPosition + 14);
            doc.save(`${title}.pdf`);
        } else {
            ToastError("Enter Mantatory fileds")
        }
    };





    const [inputs, setInputs] = useState([])

    const onSubmit = () => {
    }

    const getItemKey = (data) => {
        return data.charAt(0).toLowerCase() + data.slice(1);
    };


    React.useEffect(() => {
        GetVehicleDetails(selectedVehicle.id).then((res) => {
            setInputs(res.data)
        })
    }, [selectedVehicle])

    React.useEffect(() => {
        GetTP().then((res) => {
            setTpData(res?.data)
        })
    }, [])

    const currentYear = new moment().year()
    const handleCalcFunction = async () => {
        const cc = Number(inputData.cC)
        if (selectedVehicle.id === 1) {
            inputData.vehicle = "Two Wheeler"
            inputData.year = 1
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let baseRateQuery = (cc <= 75 && cc >= 0) ? "<75" : (cc <= 150 && cc >= 75) ? ">75" : (cc <= 350 && cc > 150) ? "<350" : ">350"
            let zoneData = ageofvehicle <= 5 ? bike?.lessThenFiveYears : (ageofvehicle <= 10 && ageofvehicle > 5) ? bike?.lessThenTenYears : bike?.greaterThenTenYears
            var tp = tpData?.twoWheeler?.oneYearTp
            var tpvalue = (cc <= 75 && cc >= 0) ? tp?.lessthan75 : (cc <= 150 && cc >= 75) ? tp?.lessthan150 : (cc <= 350 && cc > 150) ? tp?.lessthan350 : tp?.greaterthan350
            var tp = {
                TP: tpvalue
            }
            var data = await calcCarAndBike(inputData, zoneData, bike, baseRateQuery, tp)
        } else if (selectedVehicle.id === 2) {
            let ageofvehicle = 0
            inputData.vehicle = "New Two Wheeler"
            let baseRateQuery = (cc <= 75 && cc >= 0) ? "<75" : (cc <= 150 && cc >= 75) ? ">75" : (cc <= 350 && cc > 150) ? "<350" : ">350"
            let zoneData = ageofvehicle <= 5 ? bike?.lessThenFiveYears : (ageofvehicle <= 10 && ageofvehicle > 5) ? bike?.lessThenTenYears : bike?.greaterThenTenYears
            var tp = tpData?.twoWheeler?.fiveYearTp
            var tpvalue = (cc <= 75 && cc >= 0) ? tp?.lessthan75 : (cc <= 150 && cc >= 75) ? tp?.lessthan150 : (cc <= 350 && cc > 150) ? tp?.lessthan350 : tp?.greaterthan350
            var tp = {
                TP: tpvalue
            }
            var data = await calcCarAndBike(inputData, zoneData, bike, baseRateQuery, tp)
        } else if (selectedVehicle.id === 3) {
            inputData.vehicle = "Private Car"
            inputData.year = 1
            const ageofvehicle = currentYear - inputData.yearOfManufacture
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            let zoneData = ageofvehicle <= 5 ? privateCar.lessThenFiveYears : (ageofvehicle <= 10 && ageofvehicle > 5) ? privateCar.lessThenTenYears : privateCar.greaterThenTenYears
            var tp = tpData.privateCar?.oneYearTp
            var tpvalue = (cc <= 1000 && cc >= 0) ? tp?.lessthan1000 : (cc <= 1500 && cc >= 1000) ? tp?.lessthan1500 : tp?.greaterthan1500
            var tp = {
                TP: tpvalue
            }
            var data = await calcCarAndBike(inputData, zoneData, privateCar, baseRateQuery, tp)

        } else if (selectedVehicle.id === 4) {
            inputData.vehicle = "New Private Car"
            const ageofvehicle = 0
            let zoneData = ageofvehicle <= 5 ? privateCar?.lessThenFiveYears : (ageofvehicle <= 10 && ageofvehicle > 5) ? privateCar?.lessThenTenYears : privateCar?.greaterThenTenYears
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            var tp = tpData?.privateCar?.threeYearTp
            var tpvalue = (cc <= 1000 && cc >= 0) ? tp?.lessthan1000 : (cc <= 1500 && cc >= 1000) ? tp?.lessthan1500 : tp?.greaterthan1500
            var tp = {
                TP: tpvalue
            }
            var data = await calcCarAndBike(inputData, zoneData, privateCar, baseRateQuery, tp)
        } else if (selectedVehicle.id == 5) {
            inputData.vehicle = "Taxi"
            let ageofvehicle = currentYear - inputData?.yearOfManufacture
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            let zoneData = ageofvehicle <= 5 ? taxi?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? taxi?.lessThenSevenYears : taxi?.greaterThenSevenYears
            var tp = tpData?.taxi
            var tpvalue = (cc <= 1000 && cc >= 0) ? tp?.lessthan1000 : (cc <= 1500 && cc >= 1000) ? tp?.lessthan1500 : tp?.greaterthan1500
            var tp = tpvalue
            var data = await calcCarAndBike(inputData, zoneData, taxi, baseRateQuery, tp)
        } else if (selectedVehicle.id === 6) {
            inputData.vehicle = "Bus"
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            let zoneData = ageofvehicle <= 5 ? bus?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? bus?.lessThenSevenYears : bus?.greaterThenSevenYears
            var tp = tpData?.bus
            var data = await calcBus(inputData, zoneData, bus, baseRateQuery, tp)
        } else if (selectedVehicle.id === 7) {
            inputData.vehicle = "School Bus"
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            let zoneData = ageofvehicle <= 5 ? bus?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? bus?.lessThenSevenYears : bus?.greaterThenSevenYears
            var tp = tpData?.schoolBus
            var data = await calcBus(inputData, zoneData, bus, baseRateQuery, tp)
        } else if (selectedVehicle.id === 8) {
            inputData.vehicle = "Three Wheeler PCV"
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let zoneData = ageofvehicle <= 5 ? threeWheeler?.pcv?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? threeWheeler?.pcv?.lessThenSevenYears : threeWheeler?.pcv?.greaterThenSevenYears
            var tp = tpData?.threeWheelerPCV
            var data = await calcThreeWheeler(inputData, zoneData, threeWheeler.pcv, tp)
        } else if (selectedVehicle.id === 9) {
            inputData.vehicle = "Goods Carrying Vehicle(3 Wheeler)"
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let zoneData = ageofvehicle <= 5 ? threeWheeler?.gcv?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? threeWheeler?.gcv?.lessThenSevenYears : threeWheeler?.gcv?.greaterThenSevenYears
            var tp = tpData?.threeWheelerGCV
            var data = await calcThreeWheeler(inputData, zoneData, threeWheeler.gcv, tp)
        } else if (selectedVehicle.id === 10) {
            inputData.vehicle = "Goods Carrying Vehicle(More Than 3 Wheeler)"
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            let zoneData = ageofvehicle <= 5 ? gcvG3?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? gcvG3?.lessThenSevenYears : gcvG3?.greaterThenSevenYears
            var tp = tpData?.morethanThreeWheelGCV
            var data = await calcBus(inputData, zoneData, gcvG3, baseRateQuery, tp)
        } else if (selectedVehicle.id === 11) {
            inputData.vehicle = "Mischelleneous"
            let ageofvehicle = currentYear - inputData.yearOfManufacture
            let baseRateQuery = cc <= 1000 ? "=1000" : (cc <= 1500 && cc > 1000) ? "<1500" : ">1500"
            let zoneData = ageofvehicle <= 5 ? miscellaneousVehicle?.lessThenFiveYears : (ageofvehicle <= 7 && ageofvehicle > 5) ? miscellaneousVehicle?.lessThenSevenYears : miscellaneousVehicle?.greaterThenSevenYears
            var data = await calcmisc(inputData, zoneData, miscellaneousVehicle, baseRateQuery)
        } else {
            return {
                error: true,
                message: "Invalid Form ID"
            }
        }
        setViewData(data)
    }

    React.useEffect(() => {
        if (isFirst) {
            setIsfirst(false)
            return;
        }
        handleCalcFunction()
    }, [inputData])


    const SectionA = [
        {
            label: "IDV",
            values: [inputData?.iDV],
            display: 'flex',
        },
        {
            label: "Year of Manufacture",
            values: [inputData?.yearOfManufacture],
            display: 'flex',
        },
        {
            label: "vehicle Basic Rate",
            values: [viewData?.vehiclebaseRate, viewData?.vehiclebaseRateValue],
            display: (viewData?.formId === 6 || viewData?.formId === 7 || viewData?.formId === 8 || viewData?.formId === 9) ? "flex" : "none",
        },
        {
            label: "LPG KIT",
            values: [viewData?.lPGKit_A?.toFixed(2) || '-'],
            display: 'flex',
        },
        {
            label: "Seating Capacity",
            values: [viewData?.seatingCapacityAmount, viewData?.seatingCapacityvalue],
            display: viewData?.formId === 6 || viewData?.formId === 7 || viewData?.formId === 10 ? 'flex' : 'none',
        },
        {
            label: "Additional GVW",
            values: [viewData?.additionalGVW || 0],
            display: viewData?.formId === 6 || viewData?.formId === 7 || viewData?.formId === 8 || viewData?.formId === 9 || viewData?.formId === 10 ? 'flex' : 'none',
        },
        {
            label: "IMT 23",
            values: [viewData?.imt23, viewData?.imt23Value || 0],
            display: viewData?.formId === 6 || viewData?.formId === 7 || viewData?.formId === 8 || viewData?.formId === 9 || viewData?.formId === 10 ? 'flex' : 'none',
        },
        {
            label: "OD Premium",
            values: [viewData?.OdPremium || 0],
            display: 'flex',
        },
        {
            label: "Discount on OD Premium",
            values: [viewData?.discountonODPremium, viewData?.discountonODPremiumValue || 0],
            display: 'flex',
        },
        {
            label: "Basic Od Premium After Discount",
            values: [viewData?.basicODPremiumAfterDiscount || 0],
            display: 'flex',
        },
        {
            label: "Accessories Value",
            values: [viewData?.accessories, viewData?.accessoriesValue || 0],
            display: 'flex',
        },
        {
            label: "Total Basic Premium",
            values: [viewData?.totalBasicPremium || 0],
            display: 'flex',
        },
        {
            label: "No Claim Bonus",
            values: [viewData?.noClaimBonus, viewData?.noClaimBonusValue || 0],
            display: 'flex',
        },
        {
            label: "Net Own Damage Premium",
            values: [viewData?.netOwnDamage || 0],
            display: 'flex',
        },
        {
            label: "Zero Dep Premium",
            values: [viewData?.zeroDepPremium, viewData?.zeroDepPremiumValue || 0],
            display: 'flex',
        },
        {
            label: "Total A [Od Premium]",
            values: [viewData?.totalAodPremium?.toFixed(2) || 0],
            display: 'flex',
        }]


    const SectionB = [
        {
            label: "CC",
            values: [inputData?.cC || '-'],
            display: (inputData?.formId === 1 || inputData?.formId === !6 || inputData?.formId === 7 || inputData?.formId === 8 || inputData?.formId === 9 || inputData?.formId === 10 ? 'flex' : 'none'),
        },
        {
            label: "Zone",
            values: [inputData?.zone || '-'],
            display: 'flex',
        },
        {
            label: "Liapility Premium(TP)",
            values: [viewData?.LP || 0],
            display: 'flex',
        },
        {
            label: "LPG KIT",
            values: [viewData?.lPGKit_B || '-'],
            display: 'flex',
        },
        {
            label: "LL to Passengers",
            values: [viewData?.llToPassengers || '-'],
            display: viewData?.formId === 6 || viewData?.formId === 7 || viewData?.formId === 8 || viewData?.formId === 9 || viewData?.formId === 10 ? 'flex' : 'none',
        },
        {
            label: "PA to Owner Driver",
            values: [viewData?.pAOwnerDriver || 0],
            display: 'flex',
        },
        {
            label: "PA to Unnamed Passenger",
            values: [viewData?.pAUnnamedPassenger || 0],
            display: !(viewData?.formId === 6),
        },
        {
            label: "TPPD Restrict",
            values: [viewData?.tppd || 0],
            display: (viewData?.formId === !6 || viewData?.formId === 8 || viewData?.formId === 9) ? 'flex' : 'none',
        },
        {
            label: "LL to Paid Driver",
            values: [viewData?.lLtoPaidDriver || 0],
            display: 'flex',
        },
        {
            label: "Coolies / Cleaner",
            values: [viewData?.CooliesAndCleaner || 0],
            display: viewData?.formId === 6 || viewData?.formId === 7 || viewData?.formId === 8 || viewData?.formId === 9 || viewData?.formId === 10 ? 'flex' : 'none',
        },
        {
            label: "Total B [Od Premium]",
            values: [viewData?.totalBodPremium || 0],
            display: 'flex',
        },
    ];

    return (
        <div className="MainRenderinContainer" style={{ overflow: 'scroll', scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }} >
            <Grid container className="Master_Header_Container" mt={2} sx={{ padding: '0 20px 0 36px' }}>
                <Grid item xs={12} sm={9.5}>
                    <Typography className="Master_Header_Heading">{`MotorCalculation -> ${title}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={1.5} className="d-flex justify-content-end">
                    <Button
                        className="Master_Header_create_Button w-100"
                        endIcon={<DownloadIcon />}
                        sx={{ width: { xs: "100%", sm: "fit-content", marginRight: '10px' } }}
                        onClick={handleDownload}
                    >
                        Download PDF
                    </Button>
                </Grid>
                <Grid item xs={12} sm={1} className="d-flex justify-content-end">
                    <Button className="Common_Button w-100" onClick={() => setOpen(false)} sx={{ padding: '0 40px' }} endIcon={<KeyboardBackspaceTwoToneIcon />}>Back</Button>
                </Grid>
            </Grid>
            <div style={{ padding: '0 8px 0 26px' }}>
                <Grid item sm={12} xs={12} className="Count_Page" sx={{ flexDirection: { xs: 'column', sm: 'row' } }} mt={2} gap={1}>
                    <Grid className="Count_1" sx={{ width: '100%' }}>
                        <Typography className="Total">{viewData?.netPremiumAB && viewData?.netPremiumAB !== NaN ? viewData?.netPremiumAB : 0}</Typography>
                        <Typography className="Title">Net Premium [A + B] </Typography>
                    </Grid>
                    <Grid className="Count_2" sx={{ width: '100%' }}>
                        <Typography className="Total">{viewData?.tax && viewData?.tax !== NaN ? viewData?.tax : 0}</Typography>
                        <Typography className="Title">Gst@18%</Typography>
                    </Grid>
                    <Grid className="Count_2" sx={{ width: '100%' }}>
                        <Typography className="Total">{viewData?.finalPremium && viewData?.finalPremium !== NaN ? viewData?.finalPremium : 0} </Typography>
                        <Typography className="Title">Final Premium</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ display: "flex", width: "100%", gap: "40px" }} mt={4}>
                    <Box sx={{ display: selectedVehicle ? "block" : "none", width: "45%" }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => onSubmit(values)}
                            innerRef={formRef}
                        >
                            {() => (
                                <Form>
                                    <Grid container spacing={2}>
                                        {inputs ? (
                                            inputs.map((item, index) => {
                                                if (
                                                    getItemKey(
                                                        item.placeholder.trim().replaceAll(" ", "")
                                                    ) !== "policyType" &&
                                                    !item.isTP &&
                                                    inputData.policyType == "TP"
                                                ) {
                                                    return null;
                                                }

                                                return (
                                                    <Grid item xs={12} sm={6} key={index}>
                                                        <FloatLabel
                                                            label={item.placeholder}
                                                            value={inputData[getItemKey(item.placeholder.trim().replaceAll(" ", ""))]}
                                                        >
                                                            {item.elementType === "input" ? (
                                                                <TextField
                                                                    onChange={(e) => setInputData({ ...inputData, [getItemKey(item.placeholder.trim().replaceAll(" ", ""))]: e.target.value })}
                                                                    className="InputFiled"
                                                                    value={inputData[getItemKey(item.placeholder.trim().replaceAll(" ", ""))]}
                                                                />
                                                            ) : (
                                                                <Autocomplete
                                                                    className="AutoComplete_InputBox w-100"
                                                                    options={item.values}
                                                                    onChange={(option, value) => setInputData({ ...inputData, [getItemKey(item.placeholder.trim().replaceAll(" ", ""))]: value?.id })}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} />
                                                                    )}
                                                                    isOptionEqualToValue={(e, v) => e?.label === v?.label}
                                                                    value={inputData[getItemKey(item.placeholder.trim().replaceAll(" ", ""))]}
                                                                />
                                                            )}
                                                        </FloatLabel>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    padding: "40px",
                                                }}
                                            >
                                                Loading the data ...
                                            </p>
                                        )}
                                        {/* <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            sx={{ display: inputs ? "block" : "none" }}
                                        >
                                            <Button className="Common_Button w-100" type="submit">
                                                Calculate
                                            </Button>
                                        </Grid> */}
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                    <Box sx={{ width: "55%", display: "flex", gap: "10px" }}>
                        <Grid sx={BoxStyle}>
                            {
                                SectionA.map((e, elKey) => (<>
                                    <Grid className="Calculations_Box" sx={{ display: e.display }} key={elKey}>
                                        <Typography className="Box_Label">{e?.label}</Typography>
                                        {e.values.length > 1 ?
                                            <>
                                                <Typography className="Box_Value" sx={{ display: e.values[0] ? 'block' : 'none' }}>{e.values[0]}</Typography>
                                                <Typography className="Box_Value" sx={{ display: e.values[1] ? 'block' : 'none' }}>{e.values[1]}</Typography>
                                            </>
                                            :
                                            <Typography className="Box_Value" sx={{ display: e.values[0] ? 'block' : 'none' }}>{e.values[0]}</Typography>
                                        }
                                    </Grid >
                                    <Divider sx={{ display: e.display }} />
                                </>
                                ))
                            }
                        </Grid>
                        <Grid sx={BoxStyle}>
                            {
                                SectionB.map((e, _i) => (<>
                                    <Grid className="Calculations_Box" sx={{ display: e.display }} key={_i}>
                                        <Typography className="Box_Label">{e?.label}</Typography>
                                        {e.values.length > 1 ?
                                            <>
                                                <Typography className="Box_Value">{e.values[0]}</Typography>
                                                <Typography className="Box_Value">{e.values[1]}</Typography>
                                            </>
                                            :
                                            <Typography className="Box_Value">{e.values[0]}</Typography>
                                        }
                                    </Grid>
                                    <Divider sx={{ display: e.display }} />
                                </>
                                ))
                            }
                        </Grid>
                    </Box>
                </Box>

            </div>
        </div >
    )
}

export default Calculations;