import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FloatLabel from "../../../UiComponents/FloatLabel/FloatLabel";
import { Formik, Form, Field } from "formik";
import {
  PostBranch,
  UpdateBranch,
  LoginPortalData,
  UpdatePolicyList,
} from "../../../Service/_index";
import CloseIcon from "@mui/icons-material/Close";
import { ToastError, ToastSuccess } from "../../../UiComponents/Toaster/Toast";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
const UpdateLoginStatus = (props) => {
  const { selectedData, formType, setOpenDrawer, title, GetActiveData } = props;
  console.log(selectedData);
  let formRef = useRef();
  const [loginPortalDetails, setLoginPortalDetails] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(
    selectedData?.userId?._id
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    selectedData?.companyId?._id
  );
  const [selectedLoginPortal, setSelectedLoginPortal] = useState(null);

  const initialValues = {
    loginId: {
      companyId: selectedCompanyId,
      userId: selectedUserId,
    },
    loginIdFull: "",
  };
  // console.log("bookingCode:", selectedData?.bookingCodeId?.bookingCode);
  const UserId = sessionStorage.getItem("UserId");

  const GetLoginPortalData = () => {
    LoginPortalData({
      userId: selectedUserId,
      companyId: selectedCompanyId,
    }).then((res) => {
      const filterData1 = res?.data.filter((item) => {
        if (item?.userId === selectedUserId) {
          if (item?.companyId === selectedCompanyId) {
            return item;
          }
        }
      });
      const UserAllData = res?.data.filter((item) => {
        if (item?.userId === "All") {
          if (item?.companyId === selectedCompanyId) {
            return item;
          }
        }
      });

      const UserAdminData = res?.data.filter((item) => {
        if (item?.userId === "Admin") {
          if (item?.companyId === selectedCompanyId) {
            return item;
          }
        }
      });

      setLoginPortalDetails([...filterData1, ...UserAllData, ...UserAdminData]);
    });
  };

  // const [bookingCode]=selectedData?.bookingCodeId?.bookingCodeData.map((e)=>e.bookingCode)
  // const GetBookingCodeDetails=()=>{
  //   GetBookingCode({isAscending:true}).then((res)=>{
  //     const bookData=res?.data;
  //     console.log("book:",bookData)
  //     // setBookingCodeValue(bookData.bookingCode);
  //   })
  // }

  React.useEffect(() => {
    if (selectedUserId) GetLoginPortalData();
    else setLoginPortalDetails([]);
  }, [selectedUserId, selectedCompanyId]);

  useEffect(() => {
    if (formType === "edit") {
      Object.keys(initialValues).forEach((el) => {
        initialValues[el] = selectedData[el];
      });
      // formRef.setFieldValue('companyName', selectedData?.companyId?.shortName)
      // formRef.setFieldValue(
      //   "bookingCode",
      //   selectedData?.bookingCodeId?.bookingCode
      // );
      // formRef.setFieldValue('product', selectedData?.productId?.product)
      // formRef.setFieldValue('policyType', selectedData?.policyTypeId?.policyType)
      // formRef.setFieldValue(
      //   "paCover",
      //   selectedData?.paCover
      // );
      // formRef.setFieldValue("")
    }
    formRef.setFieldValue(initialValues);
  }, []);

  const onSubmit = (e, data, keys) => {
    console.log("Data:", data);
    // return true
    if (data?.loginIdFull) {
      if (e.key === "Enter" || keys === "Save") {
        const UpdatedTime = new Date();
        // data.branchName = data?.branchName?.toUpperCase()
        // data.remarks = data?.remarks?.toUpperCase()
        data.updatedAt = UpdatedTime;
        if (formType === "add") {
          data.createdBy = UserId;
        } else if (formType === "edit") {
          data.updatedBy = UserId;
        }
        let SubmitType =
          formType === "edit"
            ? UpdatePolicyList(selectedData?._id, data)
            : null;
        SubmitType.then((res) => {
          GetActiveData();
          // ToastSuccess(res.message);
          setOpenDrawer(false);
        }).catch((error) => {
          console.log(error);
        });
      }
    } else {
      ToastError("Choose the loginId");
    }
  };
  return (
    <>
      <div className="MainRenderinContainer">
        <Grid container className="DrawerHeader">
          <Grid item xs={6} sm={6}>
            <Typography>Update Login ID</Typography>
          </Grid>
          <Grid item xs={6} sm={6} className="d-flex justify-content-end">
            <CloseIcon
              onClick={() => setOpenDrawer(false)}
              sx={{ cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <div className="container-fluid">
          <Formik
            initialValues={initialValues}
            // onSubmit={(values) => {
            //   onSubmit(values);
            // }}
            innerRef={(ref) => {
              if (ref) {
                formRef = ref;
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Policy Number"
                      value={selectedData?.policyNumber}
                    >
                      <Field
                        name="policyNumber"
                        className="InputFiled"
                        style={{ textTransform: "uppercase" }}
                        value={selectedData?.policyNumber.replace("'", "")}
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Company Name"
                      value={selectedData?.companyId?.shortName}
                    >
                      <Field
                        name="companyName"
                        className="InputFiled"
                        value={selectedData?.companyId?.shortName}
                        style={{ textTransform: "uppercase" }}
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Booking Code"
                      value={selectedData?.bookingCodeId?.bookingCode}
                    >
                      <Field
                        name="bookingCode"
                        className="InputFiled"
                        value={selectedData?.bookingCodeId?.bookingCode}
                        style={{ textTransform: "uppercase" }}
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Reg Number"
                      value={selectedData?.registrationNumber}
                    >
                      <Field
                        name="registrationNumber"
                        value={selectedData?.registrationNumber}
                        className="InputFiled"
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FloatLabel
                      label="Product"
                      value={selectedData?.productId?.product}
                    >
                      <Field
                        name="product"
                        className="InputFiled"
                        value={selectedData?.productId?.product}
                        style={{ textTransform: "uppercase" }}
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FloatLabel
                      label="Policy Type"
                      value={selectedData?.policyTypeId?.policyType}
                    >
                      <Field
                        name="policyType"
                        className="InputFiled"
                        value={selectedData?.policyTypeId?.policyType}
                        style={{ textTransform: "uppercase" }}
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FloatLabel label="PA Cover" value={selectedData?.paCover}>
                      <Field
                        name="paCover"
                        className="InputFiled"
                        value={selectedData?.paCover}
                        style={{ textTransform: "uppercase" }}
                        disabled
                      />
                    </FloatLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FloatLabel
                      label="Select LoginId"
                      value={values?.loginIdFull}
                      important="*"
                    >
                      <Autocomplete
                        name="loginIdFull"
                        disablePortal
                        className="AutoComplete_InputBox"
                        id="combo-box-demo"
                        value={selectedLoginPortal}
                        options={loginPortalDetails}
                        onChange={(e, v) => {
                          setSelectedLoginPortal(v?.loginPortal);
                          setFieldValue("loginId.companyId", v?.companyId);
                          setFieldValue("loginId.userId", v?.userId);
                          setFieldValue("loginIdFull", v?.loginPortal);
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.loginPortal}
                          </li>
                        )}
                        isOptionEqualToValue={(e, v) => e.loginPortal === v}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      {/* <div className="errorMessage mb-2 mt-2">
                          {errors.loginIdFull && touched.loginIdFull ? (
                            <div>{errors.loginIdFull}</div>
                          ) : (
                            " "
                          )}
                        </div> */}
                    </FloatLabel>
                  </Grid>
                  <Grid className="DrawerFooter" item xs={12} sm={12} gap={1}>
                    <button onClick={() => setOpenDrawer(false)}>Cancel</button>
                    <button
                      // type="submit"
                      onClick={(e) => onSubmit(e, values, "Save")}
                    >
                      {selectedData?._id ? "Update" : "Save"}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateLoginStatus;
