import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FloatLabel from "../../UiComponents/FloatLabel/FloatLabel";
import { Formik, Form, Field } from "formik";
import {
  PostPolicyType,
  UpdatePolicyType,
  saveWalletPassword,
  updateWalletPasswordByUserId,
} from "../../Service/_index";
import CloseIcon from "@mui/icons-material/Close";
import { ToastError, ToastSuccess } from "../../UiComponents/Toaster/Toast";
import moment from "moment";
import Button from "@mui/material/Button";

const initialValues = {
  password: "",
};
const WalletPassword = (props) => {
  const {
    selectedData,
    formType,
    setOpenDrawer,
    setDisplayResetPassword,
    title,
    GetData,
    closeDialog,
  } = props;
  let formRef = useRef();
  const UserId = sessionStorage.getItem("UserId");

  useEffect(() => {
    formRef.setFieldValue(initialValues);
  }, []);

  const onKeyDownHandler = (e, values) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(e, values, "Save");
    }
  };

  const removePassword = () => {
    let data = {};
    data.requestType = "Delete";
    updateWalletPasswordByUserId(data).then((res) => {
      if (res) {
        setOpenDrawer(false);
        setDisplayResetPassword(false);
        ToastSuccess("Wallet Password removed successfully");
      }
    });
  };

  const onSubmit = (e, data, keys) => {
    if (e.key === "Enter" || keys === "Save") {
      if (data?.password) {
        if (formType === "add") {
          data.createdBy = UserId;
        } else if (formType === "edit") {
          data.updatedBy = UserId;
        } else if (formType === "check") {
          if (!selectedData.walletPassword) {
            ToastError("Please create wallet password");
            setOpenDrawer(false);
            return true;
          } else if (data.password == selectedData?.walletPassword) {
            setOpenDrawer(false);
            closeDialog();
            return true;
          } else {
            ToastError("Invalid Password");
            return true;
          }
        }
        let SubmitType =
          formType === "edit"
            ? updateWalletPasswordByUserId(data)
            : saveWalletPassword(data);
        SubmitType.then((res) => {
          console.log("res ", res);
          if (!res) {
            setOpenDrawer(true);
          } else {
            ToastSuccess(res.message);
            setOpenDrawer(false);
            if (formType == "add") {
              GetData();
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      } else {
        ToastError("Please Fill Password field!!");
      }
    }
  };
  return (
    <div>
      <Grid container className="DrawerHeader">
        <Grid item xs={6} sm={6}>
          <Typography>{title} Wallet Password</Typography>
        </Grid>
        <Grid item xs={6} sm={6} className="d-flex justify-content-end">
          <CloseIcon
            onClick={() => setOpenDrawer(false)}
            sx={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        innerRef={(ref) => {
          if (ref) {
            formRef = ref;
          }
        }}
      >
        {({ values }) => (
          <Form onKeyDown={(e) => onKeyDownHandler(e, values)}>
            <div className="container pb-3">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FloatLabel label="Wallet Pasword" value={values.password}>
                    <Field name="password" className="InputFiled" />
                  </FloatLabel>
                </Grid>
              </Grid>
            </div>

            <div className="Dialog_Footer">
              <Button
                onClick={() => removePassword()}
                className="Dialog_Cancel"
                sx={{ display: formType === "edit" ? "block" : "none" }}
              >
                Remove Password
              </Button>
              <Button
                onClick={() => setOpenDrawer(false)}
                className="Dialog_Cancel"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={(e) => onSubmit(e, values, "Save")}
                className="Dialog_Save"
                sx={{ display: formType === "view" ? "none" : "block" }}
              >
                {formType == "add"
                  ? "Save"
                  : formType == "check"
                  ? "Statement"
                  : "Update"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WalletPassword;
